<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Remember your password ?
      </span>
      <router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'login' }">
        Sign In!
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="login-form login-signin w-sm-475px">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Reset Password</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your details to reset your password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" v-if="!token" class="alert show alert-danger">
          <div class="alert-text">Reset link is invalid</div>
        </div>

        <div v-if="success" role="alert" class="alert show alert-success">
          <div class="alert-text">Your password successfully reseted.</div>
        </div>

        <!--begin::Action-->
        <div v-if="!token || success" class="form-group d-flex flex-wrap flex-center justify-content-between">
          <router-link class="text-dark-60 text-hover-primary my-3 mr-2" to="/forgot-password">
            Forgot Password ?
          </router-link>
          <button
            key="sign-in"
            v-on:click.prevent="$router.push('login')"
            class="btn btn-primary font-weight-bold font-size-3 px-9 py-4 ml-4"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->

        <template v-else>
          <b-form-group label-for="password">
            <template v-slot:label>
              Password (6 characters minimum)
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              type="password"
              id="password"
              name="password"
              v-model="$v.form.password.$model"
              :state="validateState($v.form.password)"
              aria-describedby="input-2-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="password-confirm">
            <template v-slot:label>
              Confirm Password
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              type="password"
              id="password-confirm"
              name="password-confirm"
              v-model="$v.form.password_confirmation.$model"
              :state="validateState($v.form.password_confirmation)"
              aria-describedby="input-3-live-feedback"
              placeholder="Confirm Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-3-live-feedback">
              Confirm Password doesn't match.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap flex-center justify-content-end">
            <button
              v-on:click.prevent="$router.push('login')"
              class="btn btn-light-primary font-weight-bold font-size-3 px-9 py-4"
            >
              Cancel
            </button>
            <button
              type="submit"
              key="submit"
              v-cs-loading="submiting"
              class="btn btn-primary font-weight-bold font-size-3 px-9 py-4 ml-4"
            >
              Submit
            </button>
          </div>
          <!--end::Action-->
        </template>
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->
  </div>
</template>

<script>
import { RESET } from '@/store/modules/auth';
import { sameAs, required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'register',
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      submiting: false,
      success: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password'),
        minLength: minLength(6),
      },
    },
  },
  computed: {
    token() {
      return this.$route.query.reset_password_token;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        password: null,
        password_confirmation: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.submiting = true;
      const params = { params: this.jsonToFormData({ admin_user: this.form }), token: this.token };
      this.$store
        .dispatch(RESET, params)
        .then(() => {
          this.success = true;
        })
        .finally(() => (this.submiting = false));
    },
  },
};
</script>
